<template>
  <div>
    <MoleculesArticleCarousel :items="latestNewses" />
    <div class="md:mx-0 mx-2 flex flex-1 flex-col gap-y-5">
      <MoleculesLatestRecipes />
      <MoleculesGoogleAd
        id="div-gpt-ad-1534156907495-0"
        target="sp"
        class="w-[336px] h-[280px] self-center"
      />
      <MoleculesGoogleAd
        id="div-gpt-ad-1534156947870-0"
        target="sp"
        class="w-[336px] h-[280px] self-center"
      />
      <MoleculesFeaturedNewses
        :latest-newses="latestNewses"
        :popular-newses="popularNewses"
      />
      <MoleculesLatestMenus />
      <MoleculesGoogleAd
        id="div-gpt-ad-1534156980762-0"
        target="sp"
        class="w-[336px] h-[280px] self-center"
      />
      <MoleculesLatestDietQAs :items="latestQAs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const title = '正しく食べて健康的にやせる';
const { getDietqaCategoryIdByName } = await useCategories();

const description =
  '「正しく食べてやせる」方法を、栄養学・ダイエット指導の専門家が提案します。バランスのよい健康ライフスタイルを、おいしくてヘルシーなレシピ、ダイエットニュースなどでご紹介します。';

useSeoMeta({
  title,
  ogType: 'website',
  description,
});

const latestNewsesResponse = await useFetch('/api/kiwi_user/articles', {
  query: {
    folder_id: 100,
    limit: 5,
    order_by: 'public_ts',
    direction: 'desc',
  },
});
const latestNewses = latestNewsesResponse.data.value?.articles ?? [];
const popularNewsesResponse = await useFetch('/api/kiwi_user/articles', {
  query: {
    folder_id: 100,
    limit: 5,
    order_by: 'page_view',
    direction: 'desc',
  },
});
const popularNewses = popularNewsesResponse.data.value?.articles ?? [];

const latestQAsResponse = await useFetch('/api/kiwi_user/articles', {
  query: {
    folder_id: 300,
    limit: 5,
    order_by: 'id',
    direction: 'asc',
    category: getDietqaCategoryIdByName('ダイエット基礎知識'),
  },
});
const latestQAs = latestQAsResponse.data.value?.articles ?? [];
</script>
