<template>
  <MoleculesTitleCard heading="新着ダイエットレシピ" color="cream">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="px-2" v-html="html" />
    <div class="border-t border-gray-300 p-4 text-right">
      <NuxtLink
        to="/public/article/recipe/?order=rank"
        class="underline font-bold"
        :external="true"
        >もっと見る</NuxtLink
      >
    </div>
  </MoleculesTitleCard>
</template>

<script setup lang="ts">
const html = ref('');

onMounted(async () => {
  const { getLatestRecipes } = useDietplusCmsApi();
  html.value = await getLatestRecipes();
});
</script>

<style scoped>
:deep(.columned-collection-container) {
  @apply flex flex-wrap;
}

:deep(.columned-item) {
  @apply w-1/2 md:w-1/3 py-4 px-2 list-none hover:opacity-70 duration-300;
}

:deep(.recipe-thumbnail) {
  @apply mb-2;
}

:deep(.energy) {
  @apply mb-2;
}

:deep(.energy i) {
  @apply inline-block;
  background-image: url('~/assets/img/fire.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 3px;
}

:deep(.energy i::before) {
  @apply w-6 h-6 inline-block;
  content: '';
}

:deep(.energy b) {
  @apply text-lg;
}

:deep(.recipe-title) {
  @apply font-bold mb-2 h-10;
}

:deep(.tags) {
  @apply text-sm;
}
</style>
