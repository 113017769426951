export function useDietplusCmsApi() {
  const config = useRuntimeConfig();

  const basePath = (() => {
    switch (config.public.APP_ENV) {
      case 'staging':
        return 'https://stg-cms-admin.dietplus.jp/api/migrations';
      default:
        return `${config.public.siteUrl}/api/migrations`;
    }
  })();

  async function getLatestMenus() {
    const response = await fetch(`${basePath}/latest_menus`).then((r) =>
      r.text(),
    );
    return response;
  }

  async function getLatestRecipes() {
    const response = await fetch(`${basePath}/latest_recipes`).then((r) =>
      r.text(),
    );
    return response;
  }

  return {
    getLatestMenus,
    getLatestRecipes,
  };
}
