<template>
  <MoleculesTitleCard heading="ダイエット献立" color="cream">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="html" />
    <div class="border-t border-gray-300 p-4 text-right">
      <NuxtLink
        to="/public/article/menu/?order=rank"
        class="underline font-bold"
        :external="true"
        >もっと見る</NuxtLink
      >
    </div>
  </MoleculesTitleCard>
</template>

<script setup lang="ts">
const html = ref('');

onMounted(async () => {
  const { getLatestMenus } = useDietplusCmsApi();
  html.value = await getLatestMenus();
});
</script>

<style scoped>
:deep(.menu-needle-horizontal) {
  @apply list-none p-4 clear-both border-b border-gray-300;
}

:deep(.menu-needle-horizontal > a) {
  @apply block overflow-auto hover:opacity-70 duration-300;
}

:deep(.menu-title) {
  @apply text-xl font-bold;
}

:deep(.energy) {
  @apply mr-2;
}

:deep(.energy i) {
  @apply inline-block;
  background-image: url('~/assets/img/fire.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 3px;
}

:deep(.energy i::before) {
  @apply w-6 h-6 inline-block;
  content: '';
}

:deep(.energy b) {
  @apply text-lg;
}

:deep(.heading) {
  @apply mb-2;
}

:deep(.main-recipe) {
  @apply mb-5 md:mb-0 md:w-1/2 md:float-left pr-5;
}

:deep(.photo) {
  @apply relative;
}

:deep(.sub-recipes) {
  @apply md:w-1/2 md:ml-[50%];
}

:deep(.recipes) {
  @apply flex flex-wrap items-start;
}

:deep(.recipe-item) {
  @apply w-1/2;
}

:deep(.recipe-item:nth-child(odd)) {
  @apply pr-4;
}

:deep(.recipe-item:nth-child(even)) {
  @apply pr-4;
}

:deep(.recipe-thumbnail) {
  @apply relative;
}

:deep(.recipe-category) {
  @apply absolute left-0 bottom-0 text-white bg-[#000000] bg-opacity-40 text-sm p-1;
}

:deep(.recipe-thumbnail img) {
  @apply mb-1;
}

:deep(.recipe-title) {
  @apply h-14 font-bold;
}

:deep(.placeholder .recipe-thumbnail img) {
  @apply h-[70px] mx-auto;
}
</style>
